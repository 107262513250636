// extracted by mini-css-extract-plugin
export var active = "Styles-module--active--81cdc";
export var benefit = "Styles-module--benefit--3ef8e";
export var benefitCont = "Styles-module--benefitCont--2f262";
export var bg = "Styles-module--bg--385d3";
export var blockImage = "Styles-module--blockImage--940f4";
export var blockLink = "Styles-module--blockLink--a2a04";
export var buttonSpace = "Styles-module--buttonSpace--62c4a";
export var carouselTitle = "Styles-module--carouselTitle--5e425";
export var container = "Styles-module--container--f01c6";
export var description = "Styles-module--description--a5982";
export var diagram = "Styles-module--diagram--78e64";
export var editor = "Styles-module--editor--c441b";
export var eshopMockup = "Styles-module--eshopMockup--b8dc7";
export var fixSectionTopSpace = "Styles-module--fixSectionTopSpace--7083f";
export var fixTopSpace = "Styles-module--fixTopSpace--2ae2f";
export var formatText = "Styles-module--formatText--9e49b";
export var fullToRight = "Styles-module--fullToRight--b68d6";
export var functions = "Styles-module--functions--8c569";
export var header = "Styles-module--header--1584e";
export var hpProjects = "Styles-module--hpProjects--5c383";
export var iconOut = "Styles-module--iconOut--2c812";
export var list = "Styles-module--list--25b4d";
export var listIcon = "Styles-module--listIcon--e1e25";
export var mainCont = "Styles-module--mainCont--83f7b";
export var menu = "Styles-module--menu--641e7";
export var rotated = "Styles-module--rotated--0f3ef";
export var sectionMarginBottom = "Styles-module--sectionMarginBottom--9f656";
export var sectionMarginTop = "Styles-module--sectionMarginTop--ef52f";
export var showAllButton = "Styles-module--showAllButton--50d15";
export var subtitle = "Styles-module--subtitle--e4874";
export var title = "Styles-module--title--f63e0";
export var topSection = "Styles-module--topSection--1588a";